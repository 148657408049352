<script>
import LiveSessionForm from "@/views/live-session/liveSessionForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility.js";

@Component({
  inject: {
    liveSessionService: "liveSessionService",
    visitorLevelService: "visitorLevelService",
    toastService: "toastService",
    visitorPermissionService: "visitorPermissionService",
    passCodeService: "passCodeService",
  },
})
export default class LiveSessionDetail extends mixins(LiveSessionForm) {
  formId = "detail-liveSession-form";

  get actions() {
    return [
      {
        id: "export-attendees",
        type: "button",
        leftIcon: "mdi-download",
        text: this.translations.labels.button_export_attendees,
        label: "Export attendees",
        handle: this.exportAttendees,
      },
      {
        id: "export-participants",
        type: "button",
        leftIcon: "mdi-download",
        text: this.translations.labels.button_export_participants,
        label: "Export participants",
        handle: this.exportParticipants,
      },
    ];
  }

  get decoratedElements() {
    const childElements = this?.ui?.childElements ?? [];
    if (childElements.includes("visitorLevels")) {
      return this.elements;
    }

    return this.insertAfter(
      this.allReadonlyFields,
      this.passCodesFieldset(false),
      "invitation"
    );
  }

  async exportAttendees() {
    const result = await this.visitorPermissionService.exportAttendees(
      "LiveSession",
      this.model.id
    );

    const columns = [
      "code",
      "email",
      "idUser",
      "firstName",
      "lastName",
      "username",
      "visitorLevels",
      "groups",
    ];

    Utility.exportResults(
      this.translations.exportLabels,
      "attendees",
      columns,
      result
    );
  }

  async exportParticipants() {
    const response = await this.liveSessionService.exportParticipants(this.model.id);
    const data = await response.blob();
    const filename = (new Date()).toISOString().substr(0, 16).replace(/[-T:.]/g, "") + "_export.csv";
    Utility.exportDownloadFile(data, filename);
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.liveSessions_detail;
  }

  async afterLoad() {
    this.model = await this.liveSessionService.read(this.$route.params.id);
    if (!this.model.invitation) {
      this.model.invitation = {};
    }
    this.model.speakers = this.model.users.map((u) => u.idUser);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.isReady = true;
  }
}
</script>
